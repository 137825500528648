<template>
  <mly-login-register @confirm="handleConfirm">
    <div class="input-group">
      <input
        type="text"
        class="login-register-input"
        maxlength="20"
        placeholder="请输入账号"
        v-model="username"
      >
      <div class="tip">
        <span>{{prompt.username}}</span>
      </div>
    </div>
    <div class="input-group">
      <input
        :type="showPassword?'text':'password'"
        class="login-register-input"
        maxlength="20"
        placeholder="请输入密码"
        v-model="pwd"
      >
      <div class="tip">
        <span>{{prompt.pwd}}</span>
      </div>
      <div
        class="icon"
        :class="{'eye-open':showPassword,'eye-close':!showPassword}"
        @click="handleChange"
      ></div>
    </div>
    <section id="tips" class="flex">
      <div class="flex" @click="rememberPassword=!rememberPassword">
        <div id="remember" class="flex">
          <img src="@/assets/icon_check.png" alt v-show="rememberPassword">
        </div>&nbsp;&nbsp;
        <span>记住密码</span>
      </div>
      <a href="javascript:void(0)" class="a-label-reset">忘记密码？</a>
    </section>
  </mly-login-register>
</template>

<script>
import axios from "axios";
import Const from "../../commons/const.js";
import util from '../../commons/util.js'
import mlyLoginRegister from "./mly-login-register";

export default {
  components: {
    mlyLoginRegister
  },
  data() {
    return {
      usernameRegex: /^(?![0-9]*$)[a-zA-Z0-9]{6,15}$/,
      showPassword: false,
      rememberPassword: false,
      username: "",
      pwd: "",
      prompt: {
        username: "",
        pwd: ""
      }
    };
  },
  methods: {
    handleChange() {
      this.showPassword = !this.showPassword;
    },
    handleConfirm() {
      if (this.username.trim() == "" || this.pwd.trim() == "") {
        return;
      }
      // if (!this.usernameRegex.test(this.username)) {
      //   this.prompt.username = "6-16位字母、数字,不能为纯数字";
      //   return;
      // } else {
      //   this.prompt.username = "";
      // }
      // if (this.pwd.length < 6 || this.pwd.length > 16) {
      //   this.prompt.pwd = "密码长度6-16个字符";
      //   return;
      // } else {
      //   this.prompt.pwd = "";
      // }
      axios
        .get("/user/login", {
          params: {
            username: this.username,
            pwd: this.pwd
          }
        })
        .then(response => {
          if (response.returnCode == Const.RequestSuccess) {
            util.setCookie(
              Const.UserCookieKey,
              response.returnData,
              this.rememberPassword ? 30 : null
            );
            GlobalObject.User = GlobalObject.UserInit();
            this.$router.push("user");
          } else {
            util.toast(response.returnMsg);
          }
        });
    }
  }
};
</script>


<style>
#tips {
  justify-content: space-between;
  /* margin: 0.42rem 0.25rem; */
  margin: 0.42rem 0rem;
}

#tips .flex {
  justify-content: center;
  align-items: center;
}

#tips span,
#tips a {
  font-weight: 100;
  font-size: 0.27rem;
  color: #999999;
}

#remember {
  width: 0.25rem;
  height: 0.25rem;
  border: 0.01rem solid #999;
  border-radius: 0.05rem;
}

#remember img {
  width: 0.17rem;
  height: 0.14rem;
}
</style>

